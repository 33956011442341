import React from 'react'
import './giftGuidesBody.css';

const GiftGuidesBody = () => {
  return (
    <div className='mydearest__giftguidesbody'>GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody GiftGuidesBody
    <a href="https://www.amazon.com/gp/product/B07N48MTTH?ie=UTF8&psc=1&linkCode=li2&tag=mydearestjuli-20&linkId=b87c759eb2184abb73177fad9ca73e2d&language=en_US&ref_=as_li_ss_il" target="_blank" rel="noreferrer"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07N48MTTH&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=mydearestjuli-20&language=en_US" alt="Sugarbear Sleep Aid Gummies for Adults with Melatonin 6mg, Magnesium, L-Theanine, 5 HTP, Vitamin B6, Valerian Root, Lemon Balm, Passionflower - Vegan Chewable Sleep Supplement, Sleep Vitamins, 60 Ct" /></a>
    

    </div>
  )
}

export default GiftGuidesBody